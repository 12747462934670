
export default {

  wordCounter(desc){
    var words = (desc || '').split(/(?:,| )+/);
    return words.length;
  },
  
  validateLogo(logo,avatar,is_test){
    if((!logo && !avatar) || (!logo.length && !avatar.length)){
      if(!is_test){
        return false;
      }
    }
    return true;
  },

  validateName(name){
    if(!name || name.length<4 || name.length>55){
      return 1;
    }else if(!(new RegExp('^([a-zA-Z0-9.\#\'-®\\s])+$','i')).test(name)){
      return 2;
    }
    return 0;
  },

  validateReleaseYear_(release_year,is_test){
    if((!release_year.id || release_year.length < 1) && !is_test){
      return 1;
    }
    if(!release_year.id && !is_test){
      return 2;
    }
    return 0;
  },

  validateReleaseYear(release_year,is_test){
    if(!release_year && !is_test){
      return 1;
    }
    if(!release_year && !is_test){
      return 2;
    }
    return 0;
  },
  
  validateDesc(desc,str_prev){
    var textarea_change = str_prev != desc ? 1 : 0;
    if(textarea_change && (this.wordCounter(desc) < 50 || this.wordCounter(desc) > 1000)){
      return false;
    }
    return true;
  },

  validateWebLink(url_website){
    if(url_website && !(new RegExp('^(?:(?:https?|http):\/\/|www\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]+$','i')).test(url_website)){
      return false;
    }
    return true;
  },
  
  validatePhotos(items_photo){
    if(items_photo && items_photo.length > 30){
      return false;
    }
    return true;
  },
  
  /* seed */
  validateSeedTypeGender(type_gender){
    if(!type_gender || type_gender.length < 1){
      return false;
    }
    return true;
  },

  validateSeedTypeFlowering(type_flowering){
    if(!type_flowering || type_flowering.length < 1){
      return false;
    }
    return true;
  },

  validateSeedTypeSource(type_source){
    if(!type_source || type_source.length < 1){
      return false;
    }
    return true;
  },

  validateSeedGenetics(gen,is_test){
    if((!gen || gen.length<4 || gen.length>140) && !is_test){
      return false;
    }
    return true;
  },

  validateSeedRooms(rooms,is_test){
    var hasError = false;
    if(rooms.length < 1 && !is_test){
      return false;
    }
    return true;
  },
  
  validateSeedHarvest(harvest_min,harvest_max,is_test){
    if(harvest_min && !(new RegExp('^[1-9]{1}[0-9]*$')).test(harvest_min)){
      return 1;
    }
    if(harvest_max && !(new RegExp('^[1-9]{1}[0-9]*$')).test(harvest_max)){
      return 2;
    }
    if(harvest_min && parseInt(harvest_min) < 1){
      return 3;
    }
    if(harvest_max && parseInt(harvest_max) < 1){
      return 4;
    }
    if(harvest_min && parseInt(harvest_min) > 999){
      return 5;
    }
    if(harvest_max && parseInt(harvest_max) > 999){
      return 6;
    }
    if(harvest_min && harvest_max && parseInt(harvest_min) >= parseInt(harvest_max)){
      return 7;
    }
    if(!harvest_max && !is_test){
      return 8;
    }
    return 0;
  },

  validateSeedFlowering(flowering_min,flowering_max){
    if(flowering_min && !(new RegExp('^[1-9]{1}[0-9]*$')).test(flowering_min)){
      return 1;
    }
    if(flowering_max && !(new RegExp('^[1-9]{1}[0-9]*$')).test(flowering_max)){
      return 2;
    }
    if(flowering_min && parseInt(flowering_min) > 99){
      return 3;
    }
    if(flowering_max && parseInt(flowering_max) > 99){
      return 4;
    }
    if(flowering_min || flowering_max){
      if(!flowering_min){
        return 5;
      }
      if(!flowering_max){
        return 6;
      }
      if(parseInt(flowering_min) >= parseInt(flowering_max)){
        return 7;
      }
      if(flowering_min && parseInt(flowering_min) < 1){
        return 8;
      }
    }
    return 0;
  },

  validateSeedThc(thc_min,thc_max){
    if(thc_min && !/^(?!0\d)\d*(\.\d{1,2})?$/.test(thc_min)){
      return 1;
    }
    if(thc_max && !/^(?!0\d)\d*(\.\d{1,2})?$/.test(thc_max)){
      return 2;
    }
    if(thc_min && (parseFloat(thc_min) < 0.1 || parseFloat(thc_min) > 30)){
      return 3;
    }
    if(thc_max && (parseFloat(thc_max) < 0.1 || parseFloat(thc_max) > 30)){
      return 4;
    }
    if(thc_min && thc_max && parseFloat(thc_min) >= parseFloat(thc_max)){
      return 5;
    }
    return 0;
  },

  validateSeedCbd(cbd_min,cbd_max){
    if(cbd_min && !/^(?!0\d)\d*(\.\d{1,2})?$/.test(cbd_min)){
      return 1;
    }
    if(cbd_max && !/^(?!0\d)\d*(\.\d{1,2})?$/.test(cbd_max)){
      return 2;
    }
    if(cbd_min && (parseFloat(cbd_min) < 0.01 || parseFloat(cbd_min) > 20)){
      return 3;
    }
    if(cbd_max && (parseFloat(cbd_max) < 0.01 || parseFloat(cbd_max) > 20)){
      return 4;
    }
    if(cbd_min && cbd_max && parseFloat(cbd_min) >= parseFloat(cbd_max)){
      return 5;
    }
    return 0;
  },

  validateSeedCbg(cbg_min,cbg_max){
    if(cbg_min && !/^(?!0\d)\d*(\.\d{1,2})?$/.test(cbg_min)){
      return 1;
    }
    if(cbg_max && !/^(?!0\d)\d*(\.\d{1,2})?$/.test(cbg_max)){
      return 2;
    }
    if(cbg_min && (parseFloat(cbg_min) < 0.1 || parseFloat(cbg_min) > 100)){
      return 3;
    }
    if(cbg_max && (parseFloat(cbg_max) < 0.1 || parseFloat(cbg_max) > 100)){
      return 4;
    }
    if(cbg_min && cbg_max && parseFloat(cbg_min) >= parseFloat(cbg_max)){
      return 5;
    }
    return 0;
  },

  validateSeedHeight(height_min,height_max){
    if(height_min && !(new RegExp('^[1-9]{1}[0-9]*$')).test(height_min)){
      return 1;
    }
    if(height_max && !(new RegExp('^[1-9]{1}[0-9]*$')).test(height_max)){
      return 2;
    }
    if(height_min && (parseInt(height_min) < 10 || parseInt(height_min) > 300)){
      return 3;
    }
    if(height_max && (parseInt(height_max) < 10 || parseInt(height_max) > 300)){
      return 4;
    }
    if(height_min && height_max && parseInt(height_min) >= parseInt(height_max)){
      return 5;
    }
    return 0;
  },

  validateSeedTastes(tastes,is_test){
    if(tastes.length > 0 && (tastes.length < 1 || tastes.length > 3)){
      return 1;
    }
    if(!tastes.length && !is_test){
      return 2;
    }
    return 0;
  },

  validateSeedEffects(effects,is_test){
    if(effects.length > 0 && (effects.length < 1 || effects.length > 3)){
      return 1;
    }
    if(!effects.length && !is_test){
      return 2;
    }
    return 0;
  },

  validateSeedVegetation(vegetation_min,vegetation_max){
    if(vegetation_min && !(new RegExp('^[1-9]{1}[0-9]*$')).test(vegetation_min)){
      return 1;
    }
    if(vegetation_max && !(new RegExp('^[1-9]{1}[0-9]*$')).test(vegetation_max)){
      return 2;
    }
    if(vegetation_min || vegetation_max){
      if(!vegetation_min){
        return 3;
      }
      if(!vegetation_max){
        return 4;
      }
      if(parseInt(vegetation_min) < 1 || parseInt(vegetation_min) > 99){
        return 5;
      }
      if(parseInt(vegetation_max) < 1 || parseInt(vegetation_max) > 99){
        return 6;
      }
      if(parseInt(vegetation_min) >= parseInt(vegetation_max)){
        return 7;
      }
    }
    return 0;
  },

  /* lamp */
  validateLampType(type){
    if(!type || type.length < 1){
      return false;
    }
    return true;
  },

  validateLampSubType(subtype){
    if(!subtype || subtype.length < 1){
      return false;
    }
    return true;
  },

  validateLampPower(power){
    if(!(new RegExp('^[1-9]{1}[0-9]*$')).test(power)){
      return 1;
    }
    if(power < 1 || power > 1500){
      return 2;
    }
    return 0;
  },

  validateLampWeigth(weigth){
    if(!(new RegExp('^[1-9]{1}[0-9]*$')).test(weigth)){
      return false;
    }
    return true;
  },

  /* tent */ 
  validateTentType(type){
    if(type.length < 1){
      return false;
    }
    return true;
  },

  validateTentPower(power){
    if(!(new RegExp('^[1-9]{1}[0-9]*$')).test(power)){
      return 1;
    }
    if(power < 1 || power > 1500){
      return 2;
    }
    return 0;
  },
 
  validateTentWidth(width){
    if(width && !(new RegExp('^[1-9]{1}[0-9]{0,5}$')).test(width)){
      return false;
    }
    return true;
  },

  validateTentLength(length){
    if(length && !(new RegExp('^[1-9]{1}[0-9]{0,5}$')).test(length)){
      return false;
    }
    return true;
  },

  validateTentHeight(height){
    if(height && !(new RegExp('^[1-9]{1}[0-9]{0,5}$')).test(height)){
      return false;
    }
    return true;
  },

  validateTentSquare(square){
    if(square && !(new RegExp('^([0-9]+.[0-9]{1,2}|[1-9]{1}[0-9]*)$')).test(square)){
      return false;
    }
    return true;
  },

  calculateTentSquare(width,length){
    var square = 0;
    width = parseInt(width);
    length = parseInt(length);
    if(width>0 && length>0){
      square = width*length/10000;
      square = Math.round(square*100)/100;
    }
    return square;
  },

  /* composition */
  validateNutrientType(type){
    if(type.length < 1){
      return false;
    }
    return true;
  },

  validateNutrientStyle(type){
    if(type.length < 1){
      return false;
    }
    return true;
  },

  validateNutrientPh(ph){
    if(ph != '' && !/^(?!0\d)\d*(\.\d{1,1})?$/.test(ph)){
      return 1;
    }
    if(ph != '' && (parseInt(ph) < 1 || parseInt(ph) > 14)){
      return 2;
    }
    return 0;
  },

  validateNutrientMacroElements(macro_elements){
    for(let val of macro_elements) {
      if(val.val == '') val.val = 0;
      if(!/^(?!0\d)\d*(\.\d{1,1})?$/.test(val.val)){
        return false;
      }
    }
    return true;
  },

  validateNutrientMicroElements(micro_elements){
    for(let val of micro_elements) {
      if(val.val == '') val.val = 0;
      if(!/^(?!0\d)\d*(\.\d{1,2})?$/.test(val.val) || val.val > 100){
        return false;
      }
    }
    return true;
  }
  
}